* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  text-align: center;
  color: #293c4b;
}

a {
  color: deepskyblue;
}

header {
  background-color: #234;
  color: white;
}
h1, h2, h3, h4 {
  margin: 0;
}

header h1 {
  font-size: 100px;
}

header h4 {
  padding: 0.5em 0 1em 0;
}
header img {
  padding: 2em;
  margin: auto;
  height: 60vh;
}

.info {
  padding: 1em 20em;
}
@media only screen and (max-width: 800px)  {
  .info {
    padding: 1em 4em;
  }
}
.wut {
  text-align: left;
  background-color: #229922;
  color: white;
}

.when {
  text-align: right;
  background-color: #3b6398;
  color: white;
}

.where {
  text-align: left;
  background-color: darkorchid;
  color: white;
}

.yup, .nope {
  display: inline-block;
  width: 50%;
}
.yup {
  padding: 1em 0 1em 5em;
}
.nope {
  padding: 1em 5em 1em 0;
}
.stepper__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1em;
}

.stepper__content {
  margin: 0;
}

.stepper__content h1 {
  margin: 0 0 20px 0;
}

.stepper__content button {
  margin: 4px;
  width: 100%;
  min-height: 40px;
  padding: 8px;
  display: block;
  font-size: 3vw;

}
